<template>
  <div id="bg">
    <div class="search">
      <div class="search-box">
        <el-button type="primary" size="medium" @click="dialogAdd = true,title = '添加固定金额配置'">添加固定金额配置</el-button>
      </div>
    </div>
    <el-table :data="tableData" :header-cell-style="{background:'#F7F8FA'}">
      <el-table-column prop="id" label="ID" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="price" label="固定金额" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="first_price" label="首充奖励金额" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="vip_day" label="赠送会员天数" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="weight" label="权重" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column label="操作" width="250px" align="center">
        <template #default="scope">
          <span class="operation" v-if="scope.row.role_id != 1" @click="editor(scope.row)">编辑</span>
          <span class="operation" v-if="scope.row.role_id != 1" @click="del(scope.row)">删除</span>
        </template>
      </el-table-column>
    </el-table>

    <!-- 添加修改 -->
    <el-dialog :title="title" v-model="dialogAdd" width="492px" top="35vh" :close-on-click-modal="false" @close="closeDialog">
      <div class="form_box">
        <div class="form_title">固定金额：</div>
        <el-input v-model="addArr.price" placeholder="固定金额" size="small"></el-input>
      </div>
      <div class="form_box">
        <div class="form_title">首充赠送金额：</div>
        <el-input v-model="addArr.first_price" placeholder="首充赠送金额" size="small"></el-input>
      </div>
      <div class="form_box">
        <div class="form_title">赠送会员天数：</div>
        <el-input v-model="addArr.vip_day" placeholder="赠送会员天数" size="small"></el-input>
      </div>
      <div class="form_box">
        <div class="form_title">权重：</div>
        <el-input v-model="addArr.weight" placeholder="权重" size="small"></el-input>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="add" v-if="title == '添加固定金额配置'">确认添加</el-button>
          <el-button type="primary" @click="edit" v-else>确认修改</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { getList, add, edit, del } from "@/api/system/recharge";
export default {
  name: "systemRecharge",
  components: {},
  data() {
    return {
      tableData: [], //数据
      row: [], //选中的数据
      title: "", //弹窗标题
      dialogAdd: false, //添加弹窗
      addArr: { price: "", first_price: "", vip_day: "", weight: "" }, //添加数组
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.getList();
  },
  methods: {
    //获取管理员数据
    getList() {
      getList()
        .then((res) => {
          if (res.code == 0) {
            this.tableData = res.data;
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //添加数据
    add() {
      this.$prompt('请输入谷歌验证码,未绑定则跳过', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPlaceholder:"请输入谷歌验证码,未绑定则跳过",
        inputErrorMessage: '谷歌验证码只能是数字'
      }).then(({ value }) => {
        add([
          { key: "price", val: this.addArr.price },
          { key: "first_price", val: this.addArr.first_price },
          { key: "vip_day", val: this.addArr.vip_day },
          { key: "weight", val: this.addArr.weight },
          { key: "google_code", val: value },
        ])
            .then((res) => {
              if (res.code == 0) {
                this.dialogAdd = false;
                this.getList();
                this.$message({ message: res.msg, type: "success" });
              } else {
                this.$message({ message: res.msg, type: "error" });
              }
            })
            .catch((err) => {
              console.log(err);
            });
      }).catch(() => {
      });

    },
    //编辑前数据填充
    editor(row) {
      this.row = row;
      this.title = `充值固定金额(ID：${row.id})`;
      this.addArr = {
        price: row.price,
        first_price: row.first_price,
        vip_day: row.vip_day,
        weight: row.weight,
      };
      this.dialogAdd = true;
    },
    //编辑
    edit() {
      this.$prompt('请输入谷歌验证码,未绑定则跳过', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPlaceholder:"请输入谷歌验证码,未绑定则跳过",
        inputErrorMessage: '谷歌验证码只能是数字'
      }).then(({ value }) => {
        edit([
          { key: "id", val: this.row.id },
          { key: "price", val: this.addArr.price },
          { key: "first_price", val: this.addArr.first_price },
          { key: "vip_day", val: this.addArr.vip_day },
          { key: "weight", val: this.addArr.weight },
          { key: "google_code", val: value },
        ])
            .then((res) => {
              if (res.code == 0) {
                this.dialogAdd = false;
                this.getList();
                this.$message({ message: res.msg, type: "success" });
              } else {
                this.$message({ message: res.msg, type: "error" });
              }
            })
            .catch((err) => {
              console.log(err);
            });
      }).catch(() => {
      });

    },
    //关闭编辑弹窗后清空
    closeDialog() {
      this.addArr = { card_type: "", weight: "" }; //清空添加修改弹窗数据
      this.row = []; //清空选中数据
    },
    //删除角色
    del(row) {
      this.row = row;
      this.$prompt('请输入谷歌验证码,未绑定则跳过', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPlaceholder:"请输入谷歌验证码,未绑定则跳过",
        inputErrorMessage: '谷歌验证码只能是数字'
      }).then(({ value }) => {
        del([{ key: "id", val: this.row.id },{ key: "google_code", val: value }])
            .then((res) => {
              if (res.code == 0) {
                this.getList();
                this.$message({ message: res.msg, type: "success" });
              } else {
                this.$message({ message: res.msg, type: "error" });
              }
            })
            .catch((err) => {
              console.log(err);
            });
      }).catch(() => {
      });
      // this.$confirm(
      //   "你确定要删除充值固定金额：" +
      //     row.id +
      //     "吗？此操作无法撤回,请谨慎操作",
      //   "提示",
      //   { confirmButtonText: "确定", cancelButtonText: "取消", type: "warning" }
      // )
      //   .then(() => {
      //     del([{ key: "id", val: this.row.id }])
      //       .then((res) => {
      //         if (res.code == 0) {
      //           this.getList();
      //           this.$message({ message: res.msg, type: "success" });
      //         } else {
      //           this.$message({ message: res.msg, type: "error" });
      //         }
      //       })
      //       .catch((err) => {
      //         console.log(err);
      //       });
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
    },
  },
};
</script>

<style scoped>
.form_box {
  overflow: hidden;
  margin-bottom: 18px;
}
.form_title {
  width: 30%;
  float: left;
  height: 32px;
  line-height: 32px;
}
.form_box >>> .el-input,
.form_box >>> .el-textarea {
  width: 60%;
}
.form_box >>> .el-select .el-input {
  width: 100%;
}
</style>
