import { qspost } from '@/config/axios';

//获取数据
export const getList = data => qspost("request?", data, "getPriceConfig");

//添加数据
export const add = data => qspost("request?", data, "addPriceConfig");

//编辑数据
export const edit = data => qspost("request?",data, "updatePriceConfig");

//删除数据
export const del = data => qspost("request?",data, "delPriceConfig");